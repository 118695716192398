<script setup lang="ts">
import { Button, Space, Tag, Tooltip } from 'ant-design-vue'
import { formatDate } from '@vueuse/core'
import type { RendererElement, RendererNode } from 'vue'
import RecentAlarm from './components/recent-alarm.vue'
import DealEventModal from './components/deal-event-modal.vue'
import { getSubSystemsApi } from '@/api/sub-system'
import { queryPlatformRuntimeEventsApi } from '@/api/event'
import { getDeviceModelsApi } from '@/api/device-model'
import { ConfirmTypeMap, EventTypeColorMap, EventTypeMap } from '@/constant/event'

const videoPath = ref('')
const historyDeviceName = ref('')
const historyVisible = ref(false)
function historyClose() {
  historyVisible.value = false
}

function showHistoryVideo(event: HistoryPlatformEvent) {
  videoPath.value = event.videoPath
  historyDeviceName.value = event.deviceName
  historyVisible.value = true
}

const liveDeviceCode = ref('')
const liveDeviceName = ref('')
const liveVisible = ref(false)
function liveClose() {
  liveVisible.value = false
}
function showLiveVideo(event: HistoryPlatformEvent) {
  liveDeviceCode.value = event.videoDeviceCode
  liveDeviceName.value = event.deviceName
  liveVisible.value = true
}

const param = ref<RuntimeEventParam>({} as RuntimeEventParam)
const search = ref<TableSearchType[]>([
  { label: '事件类型', name: 'eventTypes', placeholder: '请选择事件类型', required: false, type: 'select', options: [
    { label: '信息', value: 'INFO' },
    { label: '告警', value: 'WARN' },
    { label: '故障', value: 'FAULT' },
  ] },
  { label: '设备名称', name: 'deviceName', placeholder: '请输入设备名称', required: false, type: 'input' },
  { label: '设备编号', name: 'deviceCode', placeholder: '请输入设备编号', required: false, type: 'input' },
])

const state = reactive<TableState>({
  data: [],
  columns: [
    { title: '事件名称', fixed: 'left', dataIndex: 'eventName', key: 'eventName', width: 200, ellipsis: true },
    { title: '事件类型', dataIndex: 'eventType', key: 'eventType', width: 120, customRender: ({ record }) => {
      return h(Tag, { color: EventTypeColorMap.get(record.eventType) ?? 'default' }, () => { return EventTypeMap.get(record.eventType) ?? '未知' })
    } },
    { title: '设备名称', dataIndex: 'deviceName', width: 200, ellipsis: true, key: 'deviceName' },
    { title: '设备编号', dataIndex: 'deviceCode', key: 'deviceCode', width: 200, ellipsis: true },
    { title: '产品名称', dataIndex: 'deviceModelName', key: 'deviceModelName', width: 200, ellipsis: true },
    { title: '子系统', dataIndex: 'subSysName', key: 'subSysName', width: 120, ellipsis: true },
    { title: '触发时间', dataIndex: 'triggeredAt', key: 'triggeredAt', width: 165, customRender: ({ record }) => (formatDate(new Date(record.triggeredAt), 'YYYY-MM-DD HH:mm:ss')) },
    { title: '事件处理', width: 100, dataIndex: 'confirmType', key: 'confirmType', customRender: ({ record }) => {
      if (record.confirmType == null)
        return '未处理'
      return h(Tooltip, { placement: 'top', title: record.confirmRemark ?? '无说明' }, () => ConfirmTypeMap.get(record.confirmType))
    } },
    { title: '处理人', width: 100, ellipsis: true, dataIndex: 'confirmUser', key: 'confirmUser', customRender: ({ record }) => (record.confirmUser ?? '') },
    { title: '处理时间', dataIndex: 'updatedAt', key: 'updatedAt', width: 165, customRender: ({ record }) => {
      if (record.confirmType != null)
        return formatDate(new Date(record.updatedAt), 'YYYY-MM-DD HH:mm:ss')
      return ''
    } },
    { title: '描述', dataIndex: 'description', width: 300, ellipsis: true },
    { title: '操作', width: 280, fixed: 'right', key: 'operation', customRender: ({ record }) => {
      const renderArr: globalThis.VNode<RendererNode, RendererElement, { [key: string]: any }>[] = []
      if (record.videoDeviceCode != null)
        renderArr.push(h(Button, { type: 'link', size: 'small', onClick: () => showLiveVideo(record) }, () => '查看监控'))
      if (record.videoPath != null)
        renderArr.push(h(Button, { type: 'link', size: 'small', onClick: () => showHistoryVideo(record) }, () => '查看录像'))

      if (record.confirmType == null)
        renderArr.push(h(Button, { type: 'link', size: 'small', onClick: () => { onDeal(record) } }, () => '处理'))

      return h(Space, { direction: 'horizontal' }, () => renderArr)
    } },
  ],
  config: {
    total: 0,
    loading: false,
    isBorder: false,
    isSerialNo: true,
    isSelection: true,
    isOperate: true,
  },
  pagination: false,
})

const dealDialogVisible = ref(false)
const toDealId = ref<number>()
const pieCount = ref<CountTriggeredPlatformEvent>({} as CountTriggeredPlatformEvent)

function onDeal(event: HistoryPlatformEvent) {
  toDealId.value = event.id
  dealDialogVisible.value = true
}

onMounted(refreshEvents)

async function refreshEvents() {
  state.config.loading = true
  const { list, count } = await queryPlatformRuntimeEventsApi(param.value)
  pieCount.value = count
  state.data = list

  setTimeout(() => {
    state.config.loading = false
  }, 500)
}

onMounted(initOptions)

async function initOptions() {
  const deviceModels = await getDeviceModelsApi()
  const options = deviceModels.map(({ deviceModelName, deviceModelCode }) => ({ label: deviceModelName, value: deviceModelCode }))
  search.value.push({ label: '产品名称', name: 'deviceModelCode', placeholder: '请选择产品', required: false, type: 'select', options })

  const subSys = await getSubSystemsApi()
  const subSysOptions = subSys.map(({ id, subSysName }) => ({ label: subSysName, value: id }))
  search.value.push({ label: '子系统', name: 'subSysId', placeholder: '请选择子系统', required: false, type: 'select', options: subSysOptions })

  search.value.push({ label: '是否处理', name: 'confirmed', placeholder: '请选择', required: false, type: 'select', options: [
    { label: '是', value: 'true' },
    { label: '否', value: 'false' },
  ] })
}

async function onSearch(form: any) {
  param.value = form
  await refreshEvents()
}

async function onDealed() {
  dealDialogVisible.value = false
  await refreshEvents()
}

function onDealCancel() {
  dealDialogVisible.value = false
}
</script>

<template>
  <page-container>
    <content-header title="实时事件" />
    <flex-content>
      <div h-full flex flex-col overflow-hidden>
        <div h-350px flex-none rounded p-12px style="background: var(--bg-color)">
          <RecentAlarm v-if="Object.keys(pieCount).length > 0" :pie-count="pieCount" />
        </div>

        <div my-8px flex-none rounded p-12px style="background: var(--bg-color)">
          <search-form :search="search" @search="onSearch" />
        </div>

        <table-layout :need-header="false">
          <template #content>
            <base-table v-bind="state" />
          </template>
        </table-layout>
      </div>
    </flex-content>

    <DealEventModal :id="toDealId" :visible="dealDialogVisible" @confirm="onDealed" @cancel="onDealCancel" />
    <HistoryVideo :video-path="videoPath" video-player-status="EASY_PLAYER" :device-name="historyDeviceName" :visible="historyVisible" @on-close="historyClose" />
    <LiveVideo :visible="liveVisible" :device-code="liveDeviceCode" :device-name="liveDeviceName" @on-close="liveClose" />
  </page-container>
</template>
