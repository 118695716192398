<script setup lang="ts">
import type { UnwrapRef } from 'vue'
import { confirmRuntimeEventApi } from '@/api/event'
import { ConfirmTypeMap, mapToSelectOptions } from '@/constant/event'

const props = defineProps({
  visible: { type: Boolean, default: false },
  id: { type: Number },
})
const emit = defineEmits(['confirm', 'cancel'])
const message = useMessage()
const confirmLoading = ref(false)
const formState: UnwrapRef<ConfirmEventParam> = reactive({ confirmRemark: '', confirmType: '', id: -1 })
const formRef = ref()

async function handleOk() {
  formRef.value?.validate().then(async () => {
    if (props.id) {
      formState.id = props.id
      const isSuccess = await confirmRuntimeEventApi(toRaw(formState))
      if (isSuccess) {
        message.success('处理成功')
        emit('confirm')
      }
      else {
        message.warn('处理失败')
      }
    }
  })
    .catch((error: any) => {
      console.warn('error', error)
    })
}

function handleCancel() {
  emit('cancel')
}
</script>

<template>
  <a-modal :open="visible" title="事件处理" :confirm-loading="confirmLoading" :width="580" @ok="handleOk" @cancel="handleCancel">
    <a-form ref="formRef" :model="formState" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }">
      <a-form-item label="处理方案" name="confirmType" :rules="[{ required: true, message: '请选择处理方案', trigger: 'change' }]">
        <a-radio-group v-model:value="formState.confirmType">
          <a-radio v-for="option in mapToSelectOptions(ConfirmTypeMap)" :key="option.value" :value="option.value">
            {{ option.label }}
          </a-radio>
        </a-radio-group>
      </a-form-item>
      <a-form-item label="处理说明" name="confirmRemark">
        <a-textarea v-model:value="formState.confirmRemark" />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<style lang="scss" scoped></style>
