<script setup lang="ts">
import type { PropType } from 'vue'
import type { ECOption } from '@/components/echarts/config'
import { EventTypeMap, mapToSelectOptions } from '@/constant/event'

const props = defineProps({
  pieCount: {
    type: Object as PropType<CountTriggeredPlatformEvent>,
    default: () => ({}),
  },
})

const selectValue = ref('WARN')
const options = mapToSelectOptions(EventTypeMap)
const pieOption = ref({})

watchEffect(() => {
  if (props.pieCount) {
    pieOption.value = getOption(props.pieCount)
  }
})

function getOption(counts: CountTriggeredPlatformEvent) {
  const key = selectValue.value.toLocaleLowerCase()
  const count = counts[key] as Count
  const byDeviceModel = count.byDeviceModel
  const bySubSys = count.bySubSys

  return {
    title: [{
      text: '按子系统统计',
      top: '45%',
      left: '55%',
      textAlign: 'center',
      textStyle: {
        fontSize: 16,
        fontWeight: 700,
        color: '#000',
        overflow: 'breakAll',
        width: 100,
        lineHeight: 20,
      },
    }, {
      text: '按产品统计',
      top: '45%',
      left: '20%',
      textAlign: 'center',
      textStyle: {
        fontSize: 16,
        fontWeight: 700,
        color: '#000',
        overflow: 'breakAll',
        width: 150,
        lineHeight: 20,
      },
    }],
    tooltip: {
      trigger: 'item',
      formatter: '{b} :  {c}',
    },
    legend: {
      icon: 'circle',
      itemStyle: { color: 'inherit' },
      top: '5%',
      left: '75%',
      orient: 'vertical',
      formatter(name: string) {
        const item = [...bySubSys, ...byDeviceModel].find(item => item.name === name)
        return item ? `${name} ${item.count}` : ''
      },
    },

    series: [{
      name: 'model',
      type: 'pie',
      radius: ['65%', '90%'],
      center: ['20%', '50%'],
      data: byDeviceModel.map(item => ({ name: item.name, value: item.count })),
      label: { show: false },
      labelLine: { show: false },
      color: ['#3BDCFF', '#BFEAFB', '#15E9C3', '#15C3E9', '#00FFFF', '#4AEAB0'],
    }, {
      name: 'subsys',
      type: 'pie',
      radius: ['65%', '90%'],
      center: ['55%', '50%'],
      data: bySubSys.map(item => ({ name: item.name, value: item.count })),
      label: { show: false },
      labelLine: { show: false },
      color: ['#3BDCFF', '#BFEAFB', '#15E9C3', '#15C3E9', '#00FFFF', '#4AEAB0'],
    }],
  } as ECOption
}
</script>

<template>
  <!-- 未来30天访问量趋势预测图 -->
  <div class="echarts">
    <div class="change-options">
      切换类型：
      <a-select v-model:value="selectValue" style="width: 200px;" placeholder="请选择" :options="options" />
    </div>
    <div v-if="Object.keys(pieOption).length > 0" class="echart-content">
      <echarts :option="pieOption" :resize="false" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.echarts {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .change-options {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  .echart-content {
    flex: 1;
  }
}
</style>
